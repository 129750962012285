import { motion, MotionProps, MotionStyle } from 'framer-motion';
import React from 'react';

export const GlowEffect = ({
  color,
  animStyle,
  scale,
  duration,
  blurRadius = 150,
  spreadRadius = 60,
  motionDivProps,
  children,
}: {
  color: string;
  animStyle?: MotionStyle;
  scale?: number[];
  duration?: number;
  blurRadius?: number;
  spreadRadius?: number;
  motionDivProps?: MotionProps;
  children?: JSX.Element;
}) => {
  const animationStyle: MotionStyle = {
    position: `absolute`,
    bottom: `60px`,
    left: 0,
    right: 0,
    marginLeft: `auto`,
    marginRight: `auto`,
    width: `1px`,
    height: `1px`,
    background: `transparent`,
    boxShadow: `0px 0px ${blurRadius}px ${spreadRadius}px ${color}`,
    pointerEvents: `none`,
    ...animStyle,
  };

  return (
    <motion.div
      animate={{
        scale: scale || [0.7, 0.85, 0.7, 1, 0.85, 1.15, 0.7],
      }}
      transition={{ repeat: Infinity, duration: duration || 5 }}
      style={animationStyle}
      {...motionDivProps}
    >
      {children}
    </motion.div>
  );
};
