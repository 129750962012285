import { useQRCode } from 'next-qrcode';
import React from 'react';
import { useAppShell } from 'ui/templates';
import { ColorId } from 'ui/theme';

export type MintQrCodeProps = {
  url: string;
  colorId?: ColorId;
  color1?: string;
  color2?: string;
  type?: string;
  quality?: number;
  level?: 'low' | 'medium' | 'quartile' | 'high';
  margin?: number;
  scale?: number;
  width?: number;
};

export const QrCode = ({
  url,
  color1,
  color2,
  colorId,
  type = `image/jpeg`,
  quality = 0.3,
  level = `high`,
  margin = 2,
  scale = 10,
  width = 200,
}: MintQrCodeProps) => {
  const { Canvas } = useQRCode();
  const { color } = useAppShell({ colorId });

  return (
    <Canvas
      text={url}
      options={{
        type,
        quality,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        level,
        margin,
        scale,
        width,
        color: {
          dark: color1 || color.text,
          light: color2 || color.primary,
        },
      }}
    />
  );
};

export default QrCode;
