// eslint-disable-next-line import/no-extraneous-dependencies
import { VStack, Center, Box } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { GlowEffect, Link, QrCode, Subheading, Title } from 'ui';
import { colors } from 'ui/theme';
import Layout from '../../components/layout';

const appLink = `https://genopets.page.link/H3Xd`;

const appleImg = `../../assets/images/landing/app-store.png`;
const androidImg = `../../assets/images/landing/play-store.png`;
const gateImg = `../../../static/imgs/gate.png`;
const genopetsGroupImg = `../../assets/images/landing/Genopets_Group.png`;

const Product = () => (
  <Layout>
    <Center
      flexDirection="column"
      w="full"
      pos="relative"
      px="16px"
      h="full"
      maxH="full"
      minH="100vh"
    >
      <VStack w="full" pos="absolute" top="0" align="center">
        <StaticImage
          placeholder="blurred"
          src={gateImg}
          quality={100}
          alt="hero-img"
        />
      </VStack>
      <Box
        position="relative"
        mt={{ base: `-100px`, sm: `-150px`, md: `-170px` }}
      >
        <GlowEffect
          color={colors.yellow.primary}
          animStyle={{ bottom: `50%` }}
        />
        <StaticImage
          placeholder="blurred"
          src={genopetsGroupImg}
          quality={100}
          alt="hero-img"
        />
      </Box>
      <Center
        flexDirection="column"
        mt={{ base: `-100px`, sm: `-150px`, md: `-170px` }}
      >
        <Title
          size="3xl"
          color={colors.teal.primary}
          textAlign="center"
          filledColor={colors.teal.opacity20}
          display={{ base: `none`, sm: `block` }}
        >
          begin your journey in the genoverse
        </Title>
        <Title
          size="3xl"
          color={colors.teal.primary}
          textAlign="center"
          filledColor={colors.teal.opacity20}
          display={{ base: `block`, sm: `none` }}
        >
          begin your journey
        </Title>
        <Subheading size="sm" color={colors.teal.text}>
          Download App Now
        </Subheading>
        <Center mt={4}>
          <QrCode url={appLink} color1="#ffffff" color2="#000000" width={128} />
        </Center>
        <Center gap={{ base: 1, sm: 2 }} mt={1}>
          <Link href="https://apps.apple.com/us/app/genopets-move-play-create/id1603131221">
            <StaticImage alt="apple img" src={appleImg} />
          </Link>
          <Link href="https://play.google.com/store/apps/details?id=me.genopets.mobile&hl=fr&pli=1">
            <StaticImage alt="android img" src={androidImg} />
          </Link>
        </Center>
      </Center>
    </Center>
  </Layout>
);

export default Product;
